<template>
    <el-dialog
            title="批量修改元数据"
            :visible.sync="dialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            :before-close="handleClose">
        <el-form size="small" :model="inputForm" :rules="dataRule" ref="inputForm" label-width="120px">
            <el-form-item label="元数据类型：" prop="valType">
                <el-select v-model="inputForm.valType" placeholder="请选择元数据类型" style="width: 100%">
                    <el-option
                            v-for="item in valType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数据限制长度：" prop="limitLength">
                <el-input @input="inputForm.limitLength = inputForm.limitLength.replace(/[^\d]/g, '')"
                          @blur="setLimitLength"
                          maxlength="3" v-model.trim="inputForm.limitLength"
                          placeholder="请输入数据集合"></el-input>
            </el-form-item>
            <el-form-item label="元数据排序：" prop="fieldSort">
                <el-input-number v-model="inputForm.fieldSort" :min="1"></el-input-number>
            </el-form-item>
        </el-form>
        <div class="text_center">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="suresData()" v-no-more-click>确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "batchEditing",
    data() {
        return {
            dialogVisible: false,
            inputForm: {
                valType: '',
                limitLength: 20,
                fieldSort: 1,
            },
            dataRule: {
                valType: [
                    {required: true, message: '元数据类型不能为空', trigger: 'change'}
                ],
            },
            valType: [
                {value: 0, label: '字符串'},
                {value: 1, label: '数字'},
                {value: 2, label: '时间'},
                {value: 3, label: '日期'},
            ],
            selectList: [],
        }
    },

    methods: {
        init(selectList) {
            this.selectList = selectList
            this.dialogVisible = true
        },

        suresData() {
            let ids = this.selectList.map((item) => {
                return item.id;
            });
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    this.$axios(this.api.original.originalDescriptionBatchEditing, {
                        ...this.inputForm,
                        ids,
                    }, "post").then((data) => {
                        if (data && data.status) {
                            this.$message.success('修改成功')
                            this.$emit('refreshList')
                            this.handleClose()
                        } else {
                            this.$message.error(res.msg)
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        setLimitLength() {
            if (!this.inputForm.limitLength) {
                this.inputForm.limitLength = 1
            }
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>