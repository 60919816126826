<template>
  <div class="page" id="reorganize">
    <el-row :gutter="10">
      <el-col :span="showList ? 5 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div class="bg-white">
          <div class="catalogueBox" v-show="showList">
            <div class="flex_l_c">
              <el-input
                  class="flex1"
                  v-model="catalogueSrh"
                  placeholder="请输入类目关键词"
                  size="small"
              ></el-input>
            </div>
            <el-table
                :data="catalogueList"
                ref="catalogueTable"
                size="small"
                height="calc(100vh - 273px)"
                class="table"
                highlight-current-row
                style="cursor: pointer"
                :show-header="false"
                @current-change="handleCurrentChange"
            >
              <el-table-column prop="resourceName" show-overflow-tooltip/>
            </el-table>
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background
                layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col :span="showList ? 19 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <div class="bg-white">
          <el-form class="query-form searchForm" size="small" ref="inputForm" :model="inputForm"
                   label-width="100px" @submit.native.prevent @keyup.enter.native="submitForm(1)">
            <el-form-item label="元数据名" prop="fileSrh">
              <el-input v-model="fileSrh"
                        placeholder="请输入元数据名"
                        size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="页面类型" prop="pageType">
              <el-select class="m_l1 m_r1" style="width: 100%" size="small" v-model="pageType"
                         placeholder="请选择页面类型">
                <el-option
                    v-for="item in pageTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <div class="flex_b_c">
              <div>
                <el-button
                    icon="el-icon-search"
                    type="primary"
                    size="small"
                    @click="submitForm(1)">查询
                </el-button>
                <el-button
                    @click="resetSearch()"
                    size="small"
                    icon="el-icon-refresh-right">重置
                </el-button>
              </div>
              <div>
                <el-button
                    icon="el-icon-plus"
                    size="small"
                    type="primary"
                    @click="view('add', masterTableId)">新增
                </el-button>
                <el-button
                    size="small"
                    icon="el-icon-edit"
                    @click="allEdit()"
                >批量修改
                </el-button>
                <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click="del()"
                >批量删除
                </el-button>
              </div>
            </div>
          </el-form>
        </div>


        <div class="bg-white" style="margin-top: 10px">
          <div class="text_right">
            <el-button
                size="small"
                icon="el-icon-upload2"
                @click="exportData"
            >批量导出
            </el-button>
            <el-button
                size="small"
                icon="el-icon-suitcase"
                @click="fieldOwnership"
            >字段归属
            </el-button>
            <el-button
                size="small"
                icon="el-icon-delete"
                @click="cancelFieldAttribution"
            >取消归属
            </el-button>
          </div>
          <div class="catalogueBox">
            <el-table
                :data="fileList"
                size="small"
                @selection-change="selectionChangeHandle"
                v-loading="loading"
                height="calc(100vh - 429px)"
                class="table"
            >
              <el-table-column type="selection" width="50"/>

              <el-table-column label="排序" width="110">
                <template slot-scope="scope">{{
                    scope.row.fieldSort
                  }}
                </template>
              </el-table-column>

              <el-table-column
                  prop="fieldName"
                  label="元数据名"
                  show-overflow-tooltip
              />

              <el-table-column
                  prop="fieldEname"
                  label="元数据字段名"
                  width="150px"
                  show-overflow-tooltip
              />

              <el-table-column
                  prop="belong"
                  label="归属别名"
                  show-overflow-tooltip
              />

              <el-table-column
                  prop="publishState"
                  label="发布状态"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                    scope.row.publishState == 0 ? "已发布" : "未发布"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                  prop="onShow"
                  label="是否显示"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                    scope.row.onShow == 0 ? "显示" : "隐藏"
                  }}
                </template>
              </el-table-column>

              <!--<el-table-column
                      prop="pageType"
                      label="页面类型"
                      show-overflow-tooltip
              >
                  <template slot-scope="scope">{{
                      scope.row.pageType == 0 ? "列表" : scope.row.pageType == 1?"表单":"高级查询"
                      }}
                  </template>
              </el-table-column>-->
              <el-table-column
                  prop="pageTypeName"
                  label="页面类型"
                  show-overflow-tooltip>
              </el-table-column>

              <el-table-column
                  prop="detailsShowType"
                  label="页面组件类型"
                  width="150px"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  {{
                    scope.row.detailsShowType == 0
                        ? "文本输入框"
                        : scope.row.detailsShowType == 1
                            ? "下拉选择框"
                            : scope.row.detailsShowType == 2
                                ? "年度选择框"
                                : scope.row.detailsShowType == 3
                                    ? "日期选择框"
                                    : scope.row.detailsShowType == 4
                                        ? "开关选择框"
                                        : scope.row.detailsShowType == 5
                                            ? "时间选择框"
                                            : scope.row.detailsShowType == 6
                                                ? "用户选择框"
                                                : ""
                  }}
                </template>
              </el-table-column>

              <el-table-column
                  prop="valType"
                  label="元数据类型"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  {{
                    scope.row.valType == 0
                        ? "字符串"
                        : scope.row.valType == 1
                            ? "数字"
                            : scope.row.valType == 2
                                ? "时间"
                                : scope.row.valType == 3
                                    ? "日期"
                                    : ""
                  }}
                </template>
              </el-table-column>

              <el-table-column
                  prop="dictType"
                  label="字典类型名称"
                  width="150px"
                  show-overflow-tooltip
              />
              <el-table-column
                  prop="limitLength"
                  label="数据限制长度"
                  width="150px"
                  show-overflow-tooltip
              />
              <el-table-column
                  prop="dataset"
                  label="数据集合"
                  show-overflow-tooltip
              />
              <el-table-column
                  prop="expressionMethod"
                  label="数据表达方式"
                  width="150px"
                  show-overflow-tooltip
              />

              <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                  <el-button type="text" size="small"
                             @click="view('edit', scope.row)"
                  >修改
                  </el-button>
                  <el-button type="text" size="small"
                             @click="view('copy', scope.row)"
                  >复制
                  </el-button>
                </template>

              </el-table-column>
            </el-table>
            <el-pagination
                @size-change="sizeChangeHandle2"
                @current-change="currentChangeHandle2"
                :current-page="pageNo2"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize2"
                :total="total2"
                background
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--新增、编辑-->
    <AddMetadataForm
        ref="addRecordForm"
        @refreshDataList="getFileList"
    ></AddMetadataForm>

    <!--资源鉴定弹窗-->
    <el-dialog
        :visible.sync="handleDialogVisible"
        width="30%"
        :close-on-click-modal="false"
        :before-close="closeFieldAttribution">
      <el-form>
        <el-form-item label="字段归属：">
          <el-select filterable size="small" v-model="belong" placeholder="请选择归属表">
            <el-option
                v-for="item in fieldAttributionList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="4" :offset="6">
            <el-button :disabled="belong == ''" @click="submitFieldAttribution" size="small" type="primary">
              确定
            </el-button>
          </el-col>
          <el-col :span="4" :offset="4">
            <el-button @click="closeFieldAttribution" size="small">取消</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <batch-editing ref="batchEditing" @refreshList="submitForm()"></batch-editing>
  </div>
</template>

<script>
import AddMetadataForm from "./addMetadataForm";
import BatchEditing from "@/views/modules/dyyg/metadata/batchEditing.vue";

export default {
  components: {BatchEditing, AddMetadataForm},
  data() {
    return {
      visibleLog: false,
      showList: true,
      // 展览
      catalogueSrh: "",
      catalogueList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      // 文物清单
      inputForm: {},
      fileSrh: "",
      pageType: null,
      fileList: [],
      pageTypes: [
        {value: 0, label: "列表"},
        {value: 1, label: "表单"},
        {value: 2, label: "高级查询"},
      ],
      dataListSelections: [],
      loading: false,
      pageNo2: 1,
      pageSize2: 10,
      total2: 0,
      moduleId: 34, // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      masterTableId: "",
      handleDialogVisible: false,
      belong: '',
      fieldAttributionList: [],
    };
  },
  watch: {
    // 实时输入查询目录
    catalogueSrh() {
      this.getCatalogue();
    },
  },
  mounted() {
    this.getCatalogue();
    this.getFileList();
    this.getFieldAttribution();
  },
  methods: {
    // 新增、编辑
    view(method, row) {
      if (method == 'add' && this.masterTableId == '') {
        this.$message.warning('请选择类目后再新增')
        return
      }
      this.$refs.addRecordForm.init(method, row);
    },
    // 目录表格多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    //查询
    submitForm(type) {
      this.pageNo2 = 1;
      this.getFileList(type);
    },
    // 查询展览
    getCatalogue() {
      this.$axios(
          this.api.original.metaDataDirectoryList,
          {
            resourceName: this.catalogueSrh,
            current: this.pageNo,
            size: this.pageSize,
            isType: 1,
          },
          "get"
      ).then((data) => {
        if (data && data.status) {
          this.catalogueList = data.data.records;

          this.total = parseInt(data.data.total);
        }
      });
    },
    // 重置
    resetSearch() {
      this.fileSrh = "";
      this.pageType = null;
      this.getFileList();
      this.masterTableId = "";
    },
    // 点击展览行
    handleCurrentChange(val) {
      this.pageNo2 = 1
      this.$refs.catalogueTable.setCurrentRow(val);
      this.masterTableId = val.id;
      this.getFileList();
    },
    // 查询目录
    getFileList(type) {
      if (type == 1) {
        this.pageNo2 = 1;
      }
      if (this.exhibitionId != "") {
        this.loading = true;
        this.$axios(
            this.api.original.metaDataList,
            {
              masterTableId: this.masterTableId,
              fieldName: this.fileSrh,
              pageType: this.pageType,
              current: this.pageNo2,
              size: this.pageSize2,
            },
            "get"
        ).then((data) => {
          if (data && data.status) {
            this.fileList = data.data.records;
            if (this.fileList != null && this.fileList.length > 0) {
              this.fileList.map(item => {
                if (item.pageTypes != null && item.pageTypes != '') {
                  let pageTypeNames = ''
                  item.pageTypes.split(",").map(item2 => {
                    if (item2 === '0') {
                      pageTypeNames += '列表,'
                    } else if (item2 === '1') {
                      pageTypeNames += '表单,'
                    } else if (item2 === '2') {
                      pageTypeNames += '高级筛选,'
                    }
                  })
                  this.$set(item, "pageTypeName", pageTypeNames.substring(0, pageTypeNames.lastIndexOf(",")))
                }
              })
            }
            this.total2 = parseInt(data.data.total);
            this.loading = false;
          }
        });
      }
    },
    // 关闭字段归属
    closeFieldAttribution() {
      this.handleDialogVisible = false
      this.belong = ''
    },
    // 获取字段归属
    getFieldAttribution() {
      this.$axios(this.api.original.getTableNames, {}, "get").then(
          (res) => {
            this.fieldAttributionList = res.data
          }
      );
    },
    // 字段归属
    submitFieldAttribution() {
      // 批量删除多个id用逗号隔开
      let ids = this.dataListSelections.map((item) => {
        return item.id;
      });
      this.$axios(this.api.rcPage.setBelong, {
        'ids': ids,
        'belong': this.belong,
      }, "post").then(
          (data) => {
            if (data.status) {
              this.$message.success(data.msg)
              this.closeFieldAttribution()
              this.getFileList();
            } else {
              this.$message.error(data.msg)
            }
          }
      );
    },

    //字段归属
    fieldOwnership() {
      if (this.dataListSelections.length <= 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.handleDialogVisible = true
    },

    // 删除、批量删除
    cancelFieldAttribution() {
      if (this.dataListSelections.length <= 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      // 批量删除多个id用逗号隔开
      let ids = this.dataListSelections.map((item) => {
        return item.id;
      });
      this.$confirm(`确定取消所选项吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios(this.api.rcPage.setBelong, {
          'ids': ids,
          'belong': null,
        }, "post").then(
            (data) => {
              if (data.status) {
                this.$message.success(data.msg)
                this.closeFieldAttribution()
                this.getFileList();
              } else {
                this.$message.error(data.msg)
              }
            }
        );
      });
    },

    //批量编辑
    allEdit() {
      if (this.dataListSelections.length <= 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.$refs.batchEditing.init(this.dataListSelections)
    },

    //导出
    exportData() {
      if (this.dataListSelections.length <= 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.dataListSelections.map((item) => {
        return item.id;
      });
      this.exportExcel(this.api.original.originalDescriptionBatchExport, {ids: ids}, '元数据管理', 'get')
      this.submitForm()
    },

    // 删除、批量删除
    del(id) {
      if (!id && this.dataListSelections.length <= 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      // 批量删除多个id用逗号隔开
      let ids =
          id ||
          this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定删除所选项吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$axios(this.api.original.metaDataRemoveByIds, ids, "post").then(
            (data) => {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
              });
              this.getFileList();
            }
        );
      });
    },
    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getCatalogue();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getCatalogue();
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getFileList();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getFileList();
    },
  },
};
</script>

<style lang="scss" scoped>
.searchForm {
  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 40%;
  }
}
</style>
